import './App.css';
import React, { useState, useEffect } from 'react';
import AppRouter from './AppRouter';

// import Home from './Pages/Home'
import Login from './Pages/Login';
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  useEffect(() => {
    // Check the sessionStorage login value on component mount
    const loginStatus = sessionStorage.getItem('login');
    setIsLoggedIn(loginStatus !== null);
  }, []);
  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  return (
      <div className='App'>
        {isLoggedIn ? <AppRouter/> : <Login onLogin={handleLogin} />}
      </div>
  );
}

export default App;
