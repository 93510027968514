import React from 'react';
import './Project.css';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import CaptureCard from '../Components/CaptureCard';
import ConfirmationDialog from '../Components/ConfirmationDialog';
import { useNavigate } from 'react-router-dom';


function Project() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [projectName, setProjectName] = useState('Project Name')
  const [iframe_link, setIframe_link] = useState('');
  const [date, setDate] = useState()
  const [captures, setCaptures] = useState([])
  const [deleteCapture, setDeleteCapture] = useState(false)
  const [captureID, setCaptureID] = useState('default')
  const handleDateChange = (event) => {
    console.log('e ',event.target.value)
    setDate(event.target.value);
  };
  const handleIframeChange = (event) => {
    setIframe_link(event.target.value);
  };

  useEffect(() => {
    
    // Function to fetch projects data from the API
    const fetchProjectName = async () => {
      try {
        const response = await axios.get(`https://nodejs.mes-design.com/api/getProject.php?projectId=${id}`);
        console.log(response.data)
        setProjectName(response.data[0].Name)
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    // Function to fetch projects data from the API
    const fetchCaptures = async () => {
      try {
        const response = await axios.get(`https://nodejs.mes-design.com/api/getCaptures.php?projectId=${id}`);
        console.log(response.data)
        setCaptures(response.data)
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProjectName(); // Call the function to fetch projects when the component mounts
    fetchCaptures();
  }, []); // Empty dependency array to ensure the effect runs only once

  const handleAddCapture = () => {    
    // Prepare the data to send in the POST request
    const postData = {
      ID: id,
      date: date.toString(),
      iframe_link: iframe_link,
    };
    console.log(postData.date)
    // Make the POST request to your Express server
    fetch('https://nodejs.mes-design.com/api/addCapture.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.token
      },
      body: JSON.stringify(postData)
    })
    .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        // Parse the response JSON
        return response.json();
      })
      .then(insertedCapture => {
        // Access the inserted project data
        console.log('Inserted capture:', insertedCapture);
        setCaptures([...captures, insertedCapture]);
        // Handle the data as needed, for example, update state or display a message
      })
      .catch(error => {
        console.error('Error creating project:', error);
        // Handle error as needed, for example, display an error message
      });
    };


    const handleDeleteCapture = (captureID) => {    
      // Prepare the data to send in the POST request
      const postData = {
        ID: id,
        captureID: captureID,
      };
      console.log(postData.date)
      // Make the POST request to your Express server
      fetch('https://nodejs.mes-design.com/api/deleteCapture.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.token
        },
        body: JSON.stringify(postData)
      })
      .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          // Parse the response JSON
          return response.json();
        })
        .then(Captures => {
          // Access the inserted project data
          console.log('Inserted capture:', Captures);
          setCaptures(Captures);
          // Handle the data as needed, for example, update state or display a message
        })
        .catch(error => {
          console.error('Error creating project:', error);
          // Handle error as needed, for example, display an error message
        });
      };

      const handleDeleteProject = () => {    
        // Prepare the data to send in the POST request
        const postData = {
          ID: id,
        };
        console.log(postData.date)
        // Make the POST request to your Express server
        fetch('https://nodejs.mes-design.com/api/deleteProject.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.token
          },
          body: JSON.stringify(postData)
        })
        .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }else{
              // Parse the response JSON
              navigate('/');
            }
            
          })
          .catch(error => {
            console.error('Error creating project:', error);
            // Handle error as needed, for example, display an error message
          });
        };
    const [isDialogVisible, setIsDialogVisible] = useState(false);

  const handleDeleteClick = (event) => {
    const myArray = event.target.id.split(" ");
    console.log('id ', myArray[0])
    if(myArray[1] == 'capture'){
      setCaptureID(myArray[0])
      setDeleteCapture(true)
      
    }else{
      console.log('project')
      setDeleteCapture(false)
    }
    setIsDialogVisible(true);
  };

  const handleConfirm = () => {
    setIsDialogVisible(false);
    if(deleteCapture){
      handleDeleteCapture(captureID)      
    }else{
      handleDeleteProject()
    }
    // Add your delete logic here
  };

  const handleCancel = () => {
    setIsDialogVisible(false);
  };
    return(
      <>
        <div className='projectNav'>
          <div className='firstCont'>
            <h1>{projectName}</h1>
            <a href={'https://viewer.mes-design.com/?projectId='+id} className='open-btn btn'>Open</a>
          </div>
          <div onClick={handleDeleteClick} id={id} value='project' className='delete-btn btn'>Delete</div>
        </div>
        <div className='inputCont'>
          <p>Add Capture:</p>
          <input type='date' name='capture-date' onChange={handleDateChange} value={date}></input>
          <input placeholder='iframe link' type='text' id='iframe_link' value={iframe_link}
          onChange={handleIframeChange}/>
          <div className='add-btn btn' onClick={handleAddCapture}>Add</div>
        </div>
        <div className='capturesCont'>
        {captures.map(capture => (
                <CaptureCard onDelete={handleDeleteClick} value='capture' id={capture['ID']} key={capture['ID']} iframe_link={capture.iframe_link} date={capture.Date}/>
            ))}
        </div>
        {isDialogVisible && (
        <ConfirmationDialog
          message="Are you sure you want to delete this item?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
      </>
      
    )
}

export default Project;
