import React from 'react'
import './CaptureCard.css'
function CaptureCard(params) {
  const id = params.id
  return (
    <div className='captureCard'>
      <p className='captureDate'>{params.date}</p>
      <a target="_blank" href={params.iframe_link}>Capture Link</a>
      <div className='btn edit-btn'>Edit</div>
      <div onClick={params.onDelete} value='capture' id={id+' capture'} className='btn delete-btn'>Delete</div>
    </div>
    
  )
}

export default CaptureCard