import './Login.css';
import React, { useState } from 'react';
// import { useNavigate } from "react-router-dom";
import axios from 'axios';

const LoginPage = ({ onLogin }) => {
    // let navigate = useNavigate(); 

  // State variables to store the username, password, and JWT token
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  

  // Function to handle form submission
  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // Call the backend API to authenticate
      const response = await axios.post('https://nodejs.mes-design.com/api/login.php', {
        username,
        password
      });

      // If authentication is successful, set the JWT token and login status
      if (response.data.token) {
        // Save the token to localStorage
        sessionStorage.setItem('login', 'true');
        onLogin();
        
      } else {
        alert('Invalid credentials.');
      }
    } catch (error) {
      console.error('Error logging in:', error);
      alert('An error occurred while logging in. Please try again later.');
    }
  };

  return (
    <div className='mainCNTLogin'>
      <h2>Login Page</h2>
        <form onSubmit={handleLogin}>
          <div>
            <label>Username:</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div>
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit">Login</button>
        </form>
    </div>
  );
};

export default LoginPage;
