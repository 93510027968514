// AppRouter.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Import your components
import Home from './Pages/Home';

// import AboutPage from './pages/AboutPage';
// import ContactPage from './pages/ContactPage';
import NotFoundPage from './Pages/NotFoundPage';
import Project from './Pages/Project';

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route index Component={Home} />
        <Route path="/projects/:id" Component={Project} />
        <Route path='*' Component={NotFoundPage} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
