import React, { useState, useEffect } from 'react';
import ProjectCard from '../Components/ProjectCard';
import axios from 'axios';
import './Home.css';



function Home() {
    const [projects, setProjects] = useState([]);
    const [projectName, setProjectName] = useState('');
    // const [iframe_link, setIframe_link] = useState('');
    const handleNameChange = (event) => {
      setProjectName(event.target.value);
    };
    // const handleIframeChange = (event) => {
    //   setIframe_link(event.target.value);
    // };
    const handleCreateClick = () => {    
      // Prepare the data to send in the POST request
      const postData = {
        projectName: projectName,
        // iframe_link: iframe_link,
      };
  
      // Make the POST request to your Express server
      fetch('https://nodejs.mes-design.com/api/createProjects.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.token
        },
        body: JSON.stringify(postData)
      })
      .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          // Parse the response JSON
          return response.json();
        })
        .then(insertedProject => {
          // Access the inserted project data
          console.log('Inserted project:', insertedProject);
          setProjects([...projects, insertedProject]);
          // Handle the data as needed, for example, update state or display a message
        })
        .catch(error => {
          console.error('Error creating project:', error);
          // Handle error as needed, for example, display an error message
        });
  };
  useEffect(() => {
    // Function to fetch projects data from the API
    const fetchProjects = async () => {
      try {
        const response = await axios.get('https://nodejs.mes-design.com/api/projects.php');
        console.log(response.data)
        setProjects(response.data); // Assuming the response data is an array of project objects
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProjects(); // Call the function to fetch projects when the component mounts
  }, []); // Empty dependency array to ensure the effect runs only once
  return (
    <div className="Home">
      <div className='newProjectCont'>
        <p>Create New Project</p>
        <input placeholder='Project Name' type='text' id='projectName' value={projectName}
        onChange={handleNameChange}/>
        {/* <input placeholder='iframe link' type='text' id='iframe_link' value={iframe_link}
        onChange={handleIframeChange}/> */}
        <div className='createBtn' id='createBtn' onClick={handleCreateClick}>Create</div>
      </div>
      <div className='RecentProjectsMainCont'>
        <div className='projectTitle'>
            <p>Recent Projects</p>
        </div>
        <div className='RecentProjectsCont'>
            {projects.map(project => (
                <ProjectCard id={project['ID']} key={project['ID']} name={project['Name']}/>
            ))}
            
            {/* <ProjectCard id='123' name='project2'/>
            <ProjectCard id='123' name='project3'/> */}
        </div>
        
      </div>
    </div>
  );
}

export default Home;
