import React from 'react'
import './ProjectCard.css'
import { Link } from 'react-router-dom'
function ProjectCard(params) {
  return (
    <div className='projectCard'>
      <p className='projectName'>{params.name}</p>
      {/* <a href={'https://viewer.mes-design.com/?projectId='+params.id} className='open'>Open</a> */}
      <Link className='open' to={`/projects/${params.id}`}>Open</Link>
    </div>
    
  )
}

export default ProjectCard